import { SerializedStyles } from "@emotion/react";
import * as React from "react";
import { styles } from "./Element.styles";

import { Icon, Icons } from "../../../../elements/Icon";

export interface IElementProps {
  name: string;
  tag?: string;
  cssStyles?: SerializedStyles;
  onClick?: React.MouseEventHandler;
  href?: string;
  url?: string;
}

export function Element({ name, tag, cssStyles, href, onClick }: IElementProps) {
  return (
    <li css={[styles.element(), cssStyles]}>
      <div css={styles.itemWrapper()}>
        <Icon icon={Icons.checkMark} css={styles.icon} />
      </div>
      {href ? (
        <a href={href} onClick={onClick} css={styles.featureLink()}>
          <span css={styles.itemWrapper()}>{name}</span>
        </a>
      ) : (
        <span css={styles.itemWrapper()}>{name}</span>
      )}

      {tag && <div css={styles.tagName}>{tag}</div>}
    </li>
  );
}
