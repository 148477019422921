import { css } from "@emotion/react";
import { mediaQuery, spacing } from "../../../core/styles";

export const styles = {
  root: () =>
    css({
      h2: {
        textAlign: "center",
      },
      ul: {
        margin: `${spacing.baseUnitMobile4} auto 0`,
        display: "flex",
        flexDirection: "column",
        [mediaQuery.tabletOrLarger]: {
          textAlign: "center",
        },
        li: {
          marginBottom: 0,
          [mediaQuery.tabletOrLarger]: {
            alignSelf: "center",
          },
        },
        "li + li": {
          marginTop: spacing.baseUnitMobile3,
        },
      },
    }),
};
