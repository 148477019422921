import React from "react";

import { styles } from "./SimpleFeatureListWidget.styles";
import { Element } from "../components/Element/Element";
import { Container } from "../../../elements/Container";

export interface IFeatureListWidgetProps {
  title: string;
  elements: string[];
  handleOnClick?: React.MouseEventHandler;
  url?: string;
}

export function SimpleFeatureListWidget({ title, elements, handleOnClick, url }: IFeatureListWidgetProps) {
  return (
    <Container>
      <div css={styles.root()}>
        <h2>{title}</h2>
        <ul>
          {elements.map((element) => (
            <Element key={`bulletpoint-${element}`} name={element} {...{ url: url, onClick: handleOnClick }} />
          ))}
        </ul>
      </div>
    </Container>
  );
}
