import { css } from "@emotion/react";
import { colors, fontSizes, letterSpacings, lineHeights, spacing } from "../../../../core/styles";

export const styles = {
  icon: () =>
    css({
      alignSelf: "flex-start",
      width: "22px",
      height: "22px",
      marginRight: "6px",
      svg: {
        transform: "scale(1.5)",
      },
      path: {
        stroke: colors.green.hex,
      },
    }),
  itemWrapper: () =>
    css({
      alignSelf: "flex-start",
    }),
  tagName: () =>
    css({
      borderRadius: "2px",
      backgroundColor: "#76B830",
      color: colors.white.hex,
      fontSize: fontSizes.body.mouse,
      letterSpacing: letterSpacings.body.mouse,
      lineHeight: lineHeights.body.mouse,
      padding: "0px 4px",
      display: "inline-block",
      fontWeight: "bold",
      marginLeft: "3px",
    }),
  element: () =>
    css({
      display: "flex",
      alignItems: "center",
      listStyle: "none",
      fontSize: fontSizes.body.horse,
      letterSpacing: letterSpacings.body.horse,
      lineHeight: lineHeights.body.horse,
      fontWeight: 700,
      marginBottom: spacing.baseUnitMobile1,
    }),
  featureLink: () =>
    css({
      alignSelf: "flex-start",
      fontSize: fontSizes.body.horse,
      fontWeight: 700,
      span: {
        color: colors.blue.hex,
      },
    }),
};
