import React from "react";
import { ModuleReportFeatures } from "../../../contentful/PageModularModule";
import { CollapsibleFeatureListWidget } from "@carfax/web-ui/components/FeatureListWidget";
import { useRouter } from "next/router";

interface Props {
  module: ModuleReportFeatures;
}

const ReportFeatures: React.FC<Props> = ({ module }) => {
  const router = useRouter();

  const handleOnClick = (e) => {
    e.preventDefault();
    if (e.currentTarget.href) {
      router.push(e.currentTarget.href);
    }
  };
  return (
    <CollapsibleFeatureListWidget
      handleOnClick={handleOnClick}
      title={module.title}
      seeMore={module.seeMore}
      seeLess={module.seeLess}
      elements={module.list}
    />
  );
};

export default ReportFeatures;
