import { css } from "@emotion/react";
import { colors, spacing, mediaQuery } from "../../../core/styles";

export const styles = {
  root: () =>
    css({
      h2: {
        textAlign: "center",
      },

      ul: {
        display: "flex",
        flexDirection: "column",
        maxWidth: "350px",
        margin: `${spacing.baseUnitMobile2} auto 0 auto`,
        [mediaQuery.tabletOrLarger]: {
          maxWidth: "700px",
          flexWrap: "wrap",
          flexDirection: "row",
          marginTop: spacing.baseUnitMobile3,
        },
      },
      li: {
        listStyle: "none",
        marginBottom: spacing.baseUnitMobile1,
        alignContent: "center",
        display: "flex",
        [mediaQuery.tabletOrLarger]: {
          maxWidth: "50%",
          paddingRight: "48px",
          flex: "1 1 50%",
        },
      },
    }),

  buttonWrapper: () =>
    css({
      display: "flex",
      margin: `${spacing.baseUnitMobile3} auto 0 auto`,
      justifyContent: "center",
      [mediaQuery.tabletOrLarger]: {
        display: "none",
      },
    }),
  buttonInner: () =>
    css({
      display: "flex",
      alignItems: "center",
    }),
  icon: (showMore) =>
    css({
      border: `solid ${colors.linkBlue.hex}`,
      borderWidth: "0 2px 2px 0",
      display: "inline-block",
      padding: "3px",
      transform: showMore ? "rotate(45deg)" : "rotate(225deg)",
      marginTop: showMore ? "-3px" : "5px",
      marginLeft: spacing.baseUnitMobile1,
    }),
  hideMobile: () =>
    css({
      display: "none !important",
      [mediaQuery.tabletOrLarger]: {
        display: "flex !important",
      },
    }),
  showOnMobile: () =>
    css({
      display: "flex !important",
      [mediaQuery.tabletOrLarger]: {
        display: "none !important",
      },
    }),
  collapseWrapper: () =>
    css({
      maxWidth: "350px",
      margin: `0 auto`,
      [mediaQuery.tabletOrLarger]: {
        display: "none",
      },
      ul: {
        margin: `0 auto`,
      },
    }),
};
