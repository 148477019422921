import React, { useState } from "react";

import { Button, ButtonVariants } from "../../../elements/Button";
import { styles } from "./CollapsibleFeatureListWidget.styles";
import { Element, IElementProps } from "../components/Element/Element";
import { Container } from "../../../elements/Container";
import Collapsible from "../../../elements/Collapsible/Collapsible";
export interface ICollapsibleFeatureListWidgetProps {
  title: string;
  seeMore: string;
  seeLess: string;
  howMuchShowOnMobile?: number;
  elements: IElementProps[];
  handleOnClick?: React.MouseEventHandler;
}

export function CollapsibleFeatureListWidget({
  title,
  elements,
  seeMore,
  seeLess,
  handleOnClick,
  howMuchShowOnMobile = 6,
}: ICollapsibleFeatureListWidgetProps) {
  const [showMore, setShowMore] = useState(true);
  const toggle = () => setShowMore(!showMore);
  const copyElements = [...elements];
  const mobileVisibleElements = copyElements.splice(0, howMuchShowOnMobile);

  return (
    <Container>
      <div css={styles.root()}>
        <h2>{title}</h2>
        <div>
          <ul>
            {mobileVisibleElements.map((element) => (
              <Element key={element.name} {...{ href: element.url, onClick: handleOnClick, ...element }} />
            ))}
            {copyElements.map((element) => (
              <Element
                key={element.name}
                cssStyles={styles.hideMobile()}
                {...{ href: element.url, onClick: handleOnClick, ...element }}
              />
            ))}
          </ul>
          <div css={styles.collapseWrapper()}>
            <Collapsible show={!showMore}>
              <ul>
                {copyElements.map((element) => (
                  <Element
                    key={element.name}
                    cssStyles={styles.showOnMobile()}
                    {...{ href: element.url, onClick: handleOnClick, ...element }}
                  />
                ))}
              </ul>
            </Collapsible>
          </div>
          {elements.length > howMuchShowOnMobile && (
            <div css={styles.buttonWrapper()}>
              <Button variant={ButtonVariants.Outlined} onClick={toggle}>
                <div css={styles.buttonInner()}>
                  {showMore ? seeMore : seeLess}
                  <div css={styles.icon(showMore)} />
                </div>
              </Button>
            </div>
          )}
        </div>
      </div>
    </Container>
  );
}
